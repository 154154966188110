import React from 'react';
import * as Sentry from '@sentry/browser';
import { injectIntl } from 'react-intl';
import { isProduction } from 'js/sign-components/common/ts-utils';
import HelloModal from 'common/components/hello-modal';
import styles from './index.scss';

export class CompleteQualifiedSignature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eIDEasyWidgetRendered: false,
    };
  }

  widgetRef = React.createRef();

  componentDidMount() {
    if (!this.state.eIDEasyWidgetRendered && this.isEIdEasy()) {
      this.renderEIdEasy();
    }
  }

  isEIdEasy = () => {
    return this.props.app.eIdProvider === 'EIdEasyProvider';
  };

  extractLanguageFromLocale = () => {
    if (this.props.intl.locale) {
      const parts = this.props.intl.locale.split('-');
      return parts[0] ?? 'en';
    }
    return 'en';
  };

  renderEIdEasy = async () => {
    await import('@eid-easy/eideasy-widget');
    const eidEasyWidget = document.createElement('eideasy-widget');

    const excludedSignatureTypes = ['evrotrust-signature'];

    const excludedCountries = ['AX', 'BE', 'CH', 'DK', 'FI', 'NO', 'SE'];

    let enabledSignatureMethods = [
      'be-id-signature',
      'dk-mitid-advanced-signature',
      'evrotrust-signature',
      'fi-id-signature',
      'itsme-qes-signature',
      'norwegian-bankid-adv-signature',
      'se-bankid-advanced-signature',
      'spid-qes-signature',
      'swiss-com-qes-signature',
    ];

    if (this.props.app.eIdHasAdditionalSigningMethods) {
      enabledSignatureMethods = enabledSignatureMethods.concat([
        'gse-adv-signature',
        'cl-ecert-advanced-signature',
        'serpro-id-advanced-signature',
      ]);
    }

    if (!this.widgetRef.current) {
      return;
    }

    // BE reuses QesUrl prop for eId's docId
    const docId = this.props.app.completeQesUrl;
    const clientId = this.props.app.eIdProviderClientId;
    const countryCode = this.props.app.eIdCountryCode ?? 'US';
    const lang = this.extractLanguageFromLocale();

    const settings = {
      debug: !isProduction(),
      // ISO 3166  two letter country code
      // Users select their own country via dropdown on widget
      countryCode,
      language: lang, // ISO 639-1 two letter language code,
      sandbox: !isProduction(),
      clientId,
      docId,
      enabledMethods: {
        signature: enabledSignatureMethods,
      },
      enabledCountries: 'all',
      onSuccess: (result) => {
        window.location.replace(result.data.signature_redirect);
      },
      onFail: (error) => {
        // eIdEasy widget will display the error message
        // console.log('error', error);
        // onFail will be called on user error such as inputting wrong id
        // so logging to Sentry as message rather than exception
        if (error.message) {
          Sentry.captureMessage(
            `eIdEasy signing widget error: ${error.message}`,
          );
        }
      },
      methodsConfigFilter: (data) => {
        data.signature.forEach((signature) => {
          if (signature.actionType.includes(excludedSignatureTypes)) {
            signature.supportedCountries = signature.supportedCountries.filter(
              (country) => {
                return !excludedCountries.includes(country);
              },
            );
          }
        });
        return data;
      },
    };

    Object.assign(eidEasyWidget, settings);

    this.widgetRef.current.appendChild(eidEasyWidget);

    // Extend the height of the widget to fit the dropdown
    const elem = document.querySelector('.AppLayout_main_Vi0wA');
    if (elem) {
      elem.style.height = elem.offsetHeight * 4;
    }

    // Set the widget header's parent div to prevent oversize on Firefox
    // No effect on Chrome/Safari/Edge
    const header = document.querySelector('.AppHeader_header_mgiwB');
    if (header) {
      header.style.height = 0;
    }

    this.setState({ eIDEasyWidgetRendered: true });
  };

  render() {
    return (
      <HelloModal
        shouldCloseOnEsc={false}
        isOpen={true}
        onRequestClose={undefined}
        showCloseIcon={false}
        shouldCloseOnOverlayClick={false}
        extraClasses={styles.completeQESModal}
        contentClasses={styles.contentQES}
      >
        {this.isEIdEasy() && (
          <div
            id="widget-holder"
            data-testid="widget-holder"
            ref={this.widgetRef}
          ></div>
        )}
      </HelloModal>
    );
  }
}

export default injectIntl(CompleteQualifiedSignature);
