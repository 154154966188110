import './index.scss';

import React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import HelloModal from 'common/components/hello-modal';
import logger from 'common/logger';
import { ErrorMessage } from 'common/messages';
import errorCodes from 'signer/api/errors';
import loadingImg from 'images/v2/common/loader_@2x.gif';
import { getBrandName } from 'js/sign-components/common/brand';
import HfReactHelper from 'js/sign-components/common/hf-react-helper';

const hsMessages = defineMessages({
  qesFailed: {
    id: '',
    description:
      'error message in modal of signer flow, shows when tyring to finish the qualified signature request',
    defaultMessage: 'Qualified signature request failed',
  },

  eIdFailed: {
    id: '',
    description:
      'error message in modal of signer flow, shows when trying to continue with electronic ID verification process',
    defaultMessage: 'eID request failed',
  },

  finishWithIDnow: {
    id: 'signer.qualified.modal.completeButton',
    description:
      'button text in modal of signer flow, when clicked finish qualified signature process with IDnow',
    defaultMessage: 'Finish with IDnow',
  },

  finishWithEId: {
    id: 'signer.eid.modal.completeButton',
    description:
      'button text in modal of signer flow, when clicked continue with electronic ID verification process',
    defaultMessage: 'Continue',
  },

  cancelQES: {
    id: 'signer.qualified.modal.cancelButton',
    description:
      'button text in modal of singer flow, when clicked closes the modal',
    defaultMessage: 'Cancel',
  },

  almostDone: {
    id: 'signer.qualified.modal.title',
    description:
      'title text of modal in signer flow, when starting the qualified signature request',
    defaultMessage: 'Almost done!',
  },

  content: {
    id: 'signer.qualified.modal.description',
    description:
      'text in modal of signer flow, informing sender that they need to finish identity verification with IDnow',
    defaultMessage:
      'For this document to have a Qualified eSignature, you will need to finish identity verification with IDnow. <a>Learn more</a>',
  },

  contentEId: {
    id: 'signer.eid.modal.description',
    description:
      'text in modal of signer flow, informing signer that they need to finish electronic ID verification',
    defaultMessage:
      'Complete with eID to finish signing the document. This should only take a few minutes. Signers will select a country and electronic ID provider for verification. <a>Learn more</a>',
  },

  acceptTermsOfService: {
    id: 'signer.qualified.modal.terms',
    description:
      'text in modal of signer flow, informing signer to accept the terms of service ',
    defaultMessage:
      'By selecting <b>{finishButtonText}</b>, I agree to the <a>{brandName} Terms</a> and to be legally bound by this document.',
  },

  loadingIdnow: {
    id: 'signer.qualified.modal.loadingText',
    description:
      'loading text of modal in signer flow, when redirecting signer to idnow page',
    defaultMessage: 'Please wait a moment while we redirect you to IDnow.',
  },

  loadingEId: {
    id: 'signer.eid.modal.loadingText',
    description:
      'loading text of modal in signer flow, when redirecting signer to eID verification page',
    defaultMessage:
      'Please wait a moment while we begin the verification process.',
  },
});

class ConfirmQualifiedSignature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };

    this.complete = this.complete.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    logger.track('ConfirmQES.componentDidMount');
  }

  complete() {
    if (!this.state.isLoading) {
      this.setState({
        isLoading: true,
      });

      const { signatureRequest, router, notifier } = this.props.app;
      const { intl } = this.props;
      const isEIdEnabled =
        this.props.location.state.signatureRequest.isEIdEnabled();
      const failedErrMsg = isEIdEnabled
        ? intl.formatMessage(hsMessages.eIdFailed)
        : intl.formatMessage(hsMessages.qesFailed);

      this.listeningAsyncResponse = true;
      logger.track('ConfirmQES.continue');
      signatureRequest
        .complete()
        .then((response) => {
          this.props.app.completeQesUrl = response.qes_url;
          this.props.app.eIdProvider = response.eid_provider;
          this.props.app.eIdProviderClientId = response.eid_provider_client_id;
          this.props.app.eIdHasAdditionalSigningMethods =
            response.eid_has_additional_signing_methods;
          router.displayFullScreenModal('completeQualifiedSignature');
        })
        .catch(() => {
          this.listeningAsyncResponse = false;
          this.setState({
            isLoading: false,
          });
          this.close();
          const error = new Error(failedErrMsg);
          error.code = errorCodes.SERVER;
          notifier.notify(ErrorMessage.create(error, this));
        });
    }
  }

  close() {
    this.props.app.router.hideFullScreenModal();
  }

  render() {
    const { intl } = this.props;

    const isEIdEnabled =
      this.props.location.state.signatureRequest.isEIdEnabled();
    const finishButtonText = isEIdEnabled
      ? intl.formatMessage(hsMessages.finishWithEId)
      : intl.formatMessage(hsMessages.finishWithIDnow);
    const loadingMessage = isEIdEnabled
      ? hsMessages.loadingEId
      : hsMessages.loadingIdnow;
    const contentMsg = isEIdEnabled
      ? hsMessages.contentEId
      : hsMessages.content;
    const learnMoreLink = isEIdEnabled
      ? HfReactHelper.getFaqUrl('articles/19695045778189')
      : HfReactHelper.getFaqUrl('articles/360056581532-Qualified-eSignature-');

    const buttons = [
      {
        type: 'secondary',
        text: intl.formatMessage(hsMessages.cancelQES),
        onClick: this.close,
      },
      { type: 'primary', text: finishButtonText, onClick: this.complete },
    ];

    const loader = (
      <div className="confirm-qes__loader__div">
        <p className="confirm-qes__description">
          {intl.formatMessage(loadingMessage)}
        </p>
        <img className="confirm-qes__loader__img" src={loadingImg} />
      </div>
    );

    const content = (
      <div>
        {intl.formatMessage(contentMsg, {
          a: (...chunks) => (
            <a href={learnMoreLink} target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
        })}
        <br />
        <br />
        {intl.formatMessage(hsMessages.acceptTermsOfService, {
          finishButtonText,
          brandName: getBrandName('S'),
          a: (...chunks) => (
            <a
              href={HfReactHelper.getWebflowUrl('terms')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
          b: (...chunks) => <b>{chunks}</b>,
        })}
      </div>
    );

    return (
      <HelloModal
        isOpen={true}
        shouldCloseOnOverlayClick={false}
        showCloseIcon={!this.listeningAsyncResponse}
        onRequestClose={this.close}
        buttons={!this.listeningAsyncResponse && buttons}
        contentLabel={
          !this.listeningAsyncResponse &&
          intl.formatMessage(hsMessages.almostDone)
        }
      >
        {this.listeningAsyncResponse ? loader : content}
      </HelloModal>
    );
  }
}

export default injectIntl(ConfirmQualifiedSignature);
