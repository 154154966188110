/* eslint-disable @typescript-eslint/no-use-before-define */
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import {
  SignatureRequest,
  SignerContextShape,
} from 'signer-app/signer-signature-document/types';

import * as Sentry from '@sentry/browser';
import React from 'react';
import {
  Field,
  CheckboxField,
  RadioField,
} from 'signer-app/types/editor-types';
import { validationRegex } from 'signer-app/signer-experience/signer-validation-constants';
import invariant from 'invariant';

function logOvertimeValidation(regex: RegExp, startTime: number) {
  const logTimeout = 2000; // When more than 2s we capture a log
  const now = Date.now();
  if (now > startTime + logTimeout) {
    Sentry.captureMessage(`Regex ${regex} took more than ${logTimeout}ms`);
  }
}

const messages = defineMessages({
  numbers_only: {
    id: 'error.validation.numbers_only',
    description:
      'error message for input field in signer flow, shows when inputted data in not numbers only',
    defaultMessage: 'This field only accepts numbers',
  },
  letters_only: {
    id: 'error.validation.letters_only',
    description:
      'error message for input field in signer flow, shows when inputted data in not letters only',
    defaultMessage: 'This field only accepts letters',
  },
  phone_number: {
    id: 'error.validation.phone_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid phone number',
    defaultMessage: 'Please enter a 10- or 11-digit telephone number',
  },
  bank_routing_number: {
    id: 'error.validation.bank_routing_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid bank routing number',
    defaultMessage: 'Please enter a 9-digit routing number',
  },
  bank_account_number: {
    id: 'error.validation.bank_account_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid account number',
    defaultMessage: 'Please enter a minimum of 6 digits',
  },
  email_address: {
    id: 'error.validation.email_address',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid email address',
    defaultMessage: 'Please enter a valid email address',
  },
  zip_code: {
    id: 'error.validation.zip_code',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid zip code',
    defaultMessage: 'Please enter a 5- or 9-digit zip code',
  },
  social_security_number: {
    id: 'error.validation.social_security_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid social security number',
    defaultMessage: 'Please enter a 9-digit social security number',
  },
  employer_identification_number: {
    id: 'error.validation.employer_identification_number',
    description:
      'error message for input field in signer flow, shows when inputted data is not valid employee ID number',
    defaultMessage: 'Please enter a 9-digit employer identification number',
  },
  custom_regex: {
    id: 'error.validation.custom_regex',
    description:
      'error message for input field in signer flow, shows when inputted data does not pass regex validation',
    defaultMessage: 'The field is invalid.',
  },
  custom_regex_with_format: {
    id: 'error.validation.custom_regex_with_format',
    description:
      'error message for input field in signer flow, shows when inputted data does not pass regex validation',
    defaultMessage: 'The field is invalid. Required format: {format}',
  },

  requiredMessage: {
    id: 'error.field.requiredMessage',
    description: 'generic error message when required field is not filled',
    defaultMessage: 'This field is required',
  },
  fieldsMissing: {
    id: 'error.field.fieldsMissing',
    description: 'generic error message when required field is not filled',
    defaultMessage: "Required fields are missing. Please click 'Edit'.",
  },
  minNotMetMessage: {
    id: 'error.field.minNotMetMessage',
    description:
      'generic error message when minimum required boxes are not selected',
    defaultMessage:
      'Please select at least {min} { min, plural, =1 {item} other {items} }',
  },
  maxExceededMessage: {
    id: 'error.field.maxExceededMessage',
    description:
      'generic error message when more then the limit of boxes are selectex',
    defaultMessage: 'You have selected too many boxes. Please uncheck one.',
  },
  generalErrorMessage: {
    id: 'error.general.message',
    description: 'general error message',
    defaultMessage: 'An error occurred',
  },
});

export function useValidation(
  signatureRequest: SignatureRequest | undefined,
  currentFieldId: string | null,
): SignerContextShape['validationErrors'] {
  const intl = useIntl();

  const [touchedFields, setTouched] = React.useState<Array<string>>([]);

  React.useEffect(() => {
    return () => {
      if (currentFieldId) {
        setTouched((touched) => {
          if (touched.includes(currentFieldId)) {
            return touched;
          }
          return touched.concat([currentFieldId]);
        });
      }
    };
  });

  const fields = signatureRequest?.fields ?? [];
  const fieldErrors = fields.reduce<SignerContextShape['validationErrors']>(
    (errors, field) => {
      // Only return 1 error at a time because that's how the current
      // implementation works
      if (Object.keys(errors).length > 0) {
        return errors;
      }

      if (!touchedFields.includes(field.id)) {
        return errors;
      }

      const result = validateField(field, intl);
      if (result) {
        errors[result.id] = { message: result.message };
      }

      return errors;
    },
    {},
  );

  const groupErrors = validateGroups(fields, intl);

  return {
    ...fieldErrors,
    ...groupErrors,
  };
}

export function validateField(
  field: Field,
  intl: IntlShape,
): null | { id: string; message: string } {
  switch (field.type) {
    case 'date':
      // Date doesn't have validation, users don't get to interact with it.
      break;
    case 'checkbox': {
      if (field.required && !field.checked) {
        return {
          id: field.id,
          message: intl.formatMessage(messages.requiredMessage),
        };
      }
      break;
    }
    case 'initials':
    case 'signature': {
      if (field.required && !field.signature) {
        return {
          id: field.id,
          message: intl.formatMessage(messages.requiredMessage),
        };
      }
      break;
    }
    case 'dropdown': {
      if (field.required && !field.value) {
        return {
          id: field.id,
          message: intl.formatMessage(messages.requiredMessage),
        };
      }

      break;
    }
    case 'text': {
      if (field.required && !field.value) {
        return {
          id: field.id,
          message: intl.formatMessage(messages.requiredMessage),
        };
      }

      let regex;
      switch (field.validationType) {
        case 'numbers_only':
        case 'letters_only':
        case 'phone_number':
        case 'bank_routing_number':
        case 'bank_account_number':
        case 'email_address':
        case 'zip_code':
        case 'social_security_number':
        case 'employer_identification_number': {
          invariant(
            validationRegex[field.validationType],
            'Unable to locate regex for validation',
          );
          regex = validationRegex[field.validationType];

          break;
        }
        case 'custom_regex':
          if (field.validationCustomRegex != null) {
            try {
              regex = new RegExp(field.validationCustomRegex);
            } catch (e) {
              // TypeScript knows that e is unknown, anything can be thrown
              // from anywhere. But I've wrapped this round a native API and
              // I'm just going to trust that I'll get an Error here.
              const error = e as Error;
              Sentry.captureMessage(
                `Error while initializing new custom regex ${error.message}`,
              );
              throw error;
            }
          }
          break;
        default:
          break;
      }

      if (field.validationType && regex) {
        const startRegexTestTime = Date.now();
        if (!regex.test(field.value?.trim().replace(/\n/g, '') ?? '')) {
          if (
            field.validationType === 'custom_regex' &&
            field.validationCustomRegexFormatLabel
          ) {
            return {
              id: field.id,
              message: intl.formatMessage(messages.custom_regex_with_format, {
                format: field.validationCustomRegexFormatLabel,
              }),
            };
          } else if (field.validationType) {
            return {
              id: field.id,
              message: intl.formatMessage(messages[field.validationType]),
            };
          }
        }
        logOvertimeValidation(regex, startRegexTestTime);
      }
      break;
    }
    default:
  }
  return null;
}

function validateGroups(fields: Field[], intl: IntlShape) {
  const groupErrors: SignerContextShape['validationErrors'] = {};
  const groupedFields = fields.reduce(
    (groups, field) => {
      if ('group' in field && field.group) {
        if (field.type === 'checkbox' || field.type === 'radiobutton') {
          groups[field.group] ??= [];
          groups[field.group].push(field);
        }
      }
      return groups;
    },
    {} as Record<string, Array<CheckboxField | RadioField>>,
  );

  return Object.entries(groupedFields).reduce(
    (errors, [group, groupFields]) => {
      const firstField = groupFields[0];
      const { requirement } = firstField;
      invariant(
        typeof requirement === 'string',
        'Requirement should be a string',
      );
      invariant(
        groupFields.every(
          (field) => field.group === group && field.requirement === requirement,
        ),
        'All fields in a group should have the same group and requirement',
      );
      const match = requirement.match(/require_(\d+)(-)?(\d+|ormore)?/);
      invariant(
        match,
        `Requirement should match the pattern. Found ${requirement}`,
      );
      const [, min, , max] = match;

      const checkedFields = groupFields.filter((field) => field.checked).length;
      if (Number(min) > checkedFields) {
        errors[group] = {
          message: intl.formatMessage(messages.minNotMetMessage, { min }),
        };
      }
      if (Number(max) > 0 && Number(max) < checkedFields) {
        errors[group] = {
          message: intl.formatMessage(messages.maxExceededMessage),
        };
      }

      return errors;
    },
    groupErrors,
  );
}
