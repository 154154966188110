import React from 'react';
import moment from 'moment';
import {
  fieldsContext,
  signatureRequestContext,
  SignatureRequestContext,
} from 'signer-app/signature-request';
import { useConditionalLogic } from 'signer-app/conditional-logic';
import { useLatestRef } from 'signer-app/utils/use-latest-ref';
import useSkipHiddenFields from 'signer-app/signer-signature-document/context/use-skip-hidden-fields';
import { getAutofillFieldMessage } from 'signer-app/signer-signature-document/context/get-autofill-fields-message';
import { Field, RadioField } from 'signer-app/types/editor-types';
import { defineMessages, useIntl } from 'react-intl';
import { unreachable, UnreachableError } from 'signer-app/utils/unreachable';
import {
  FieldUpdate,
  SignatureRequest,
  SignerContextShape,
} from 'signer-app/signer-signature-document/types';
import { DocumentAddress } from 'signer-app/signature-request/context';
import { useNotificationBanners } from 'signer-app/utils/notification-banner-context';
import SignedDataModal from 'signer-app/signer-signature-document/signed-data-modal';

const messages = defineMessages({
  hiddenFieldsNotification: {
    id: '0bfdfea5c1cf3e02b49b997199ff2da1d2cdd4289302e3c1ba2e3da846b1e857',
    description:
      'notification in webpage asking user to double check to ensure relevent information is present',
    defaultMessage:
      "This document is adaptive and will show/hide fields depending on your answers to ensure you're only filling in relevant information! Make sure to double check your answers before submitting.",
  },
  alertTextAutofill: {
    id: '',
    description:
      'signer app, autofillable fields, notification to user, informs user that changing selected field will change automatically N-number of fields of same type',
    defaultMessage: `{fieldNumber, plural,
      one {Changes you make in this field will be reflected in # other {autoFillType} field}
      other {Changes you make in this field will be reflected in # other {autoFillType} fields}}`,
  },
  validationPreview: {
    id: 'd05a3a449d43138ff0a38027cd2c64b656ab8bca9856f5a0200a847488185ecf',
    description:
      'A banner message to show when users encounter a validated field',
    defaultMessage:
      'This field has validation applied to it but it can’t be shown in the preview.',
  },
});

function serializeFieldType(field: Field) {
  const componentType = {
    signature: 'inputsignature',
    date: 'inputdate',
    initials: 'inputinitials',
    text: 'inputtextbox',
    checkbox: 'inputcheckmark',
    dropdown: 'inputdropdown',
    radiobutton: 'inputradiobutton',
    hyperlink: 'inputhyperlink',
    rectangle: undefined,
    image: undefined,
  }[field.type];

  if (!componentType) {
    throw new Error(`Unknown component type: ${field.type}`);
  }

  return componentType;
}

function ValidationNotification() {
  const f = React.useContext(signatureRequestContext);
  const fields = React.useContext(fieldsContext);
  const { clearNotification, sendNotification } = useNotificationBanners();

  const currentField = React.useMemo(() => {
    const selectedFields = f.selectedFieldIds.map((id) =>
      fields.find((f) => f.id === id),
    );
    if (selectedFields.length === 1) {
      return selectedFields[0];
    }
    return null;
  }, [f.selectedFieldIds, fields]);

  const showValidationBanner =
    currentField && currentField.type === 'text' && currentField.validationType;

  React.useEffect(() => {
    if (showValidationBanner) {
      const id = sendNotification({
        autoClose: true,
        delay: 4000,
        message: messages.validationPreview,
        type: 'attention',
        withCloseButton: true,
      });
      return () => clearNotification(id);
    }

    return () => {};
  }, [sendNotification, clearNotification, currentField, showValidationBanner]);

  return null;
}

type Props = React.PropsWithChildren<{
  signatureRequest: SignatureRequest;
  fieldResetKey?: string;
  selectedFieldIds: Array<Field['id']>;
  setCurrentField: SignerContextShape['selectField'];
  onFieldUpdate?: (field: Field, updates: Partial<Field>) => void;
  onPageClick?: () => void;
  startEnabled: SignerContextShape['startEnabled'];
  validationErrors: SignerContextShape['validationErrors'];
  defaultDateFormat: string;
  getInstantValidationErrorHack?: SignerContextShape['getInstantValidationErrorHack'];
  documentPreview?: boolean;
  inlinePreviewMode?: boolean;
  documentTitle?: string;
  lastUpdatedFieldId?: Field['id'];
  containerWidth?: number | null;
  onPrefill?: () => void;
}>;

export const signerContext = React.createContext<SignerContextShape>(
  {} as SignerContextShape,
);

function useLinkNotification(currentFieldId: string, fields: Field[]) {
  const { sendNotification, clearNotification } = useNotificationBanners();
  const intl = useIntl();

  const currentField = React.useMemo(
    () => fields.find((field) => field.id === currentFieldId),
    [currentFieldId, fields],
  );
  const numLinkedFields = React.useMemo(() => {
    if (currentField && currentField.linkId) {
      return fields.filter(
        (f) =>
          f.linkId &&
          f.id !== currentField.id &&
          f.linkId === currentField.linkId,
      ).length;
    }
    return 0;
  }, [currentField, fields]);

  React.useEffect(() => {
    if (currentField && numLinkedFields > 0 && currentField.autoFillType) {
      const autoFillMessage = getAutofillFieldMessage(
        currentField.autoFillType,
      );
      const autoFillType =
        autoFillMessage !== '' ? intl.formatMessage(autoFillMessage) : '';

      const id = sendNotification({
        autoClose: true,
        delay: 4000,
        message: intl.formatMessage(messages.alertTextAutofill, {
          fieldNumber: numLinkedFields,
          autoFillType,
        }),
        type: 'attention',
        withCloseButton: true,
      });

      return () => clearNotification(id);
    }
    return () => {};
  }, [
    clearNotification,
    sendNotification,
    currentField,
    numLinkedFields,
    intl,
  ]);
}

const NOOP = () => {};
export default function SignerContext({
  signatureRequest,
  children,
  selectedFieldIds,
  setCurrentField,
  onFieldUpdate = NOOP,
  onPageClick,
  startEnabled,
  validationErrors,
  defaultDateFormat,
  getInstantValidationErrorHack,
  documentPreview = false,
  fieldResetKey,
  inlinePreviewMode = false,
  documentTitle,
  lastUpdatedFieldId,
  containerWidth = null,
  onPrefill,
}: Props) {
  const intl = useIntl();
  const { sendNotification } = useNotificationBanners();

  const normalizeField = React.useCallback(
    (f) => {
      if (f.type === 'date' && !inlinePreviewMode) {
        // set the date format that is passed in
        let dateFormat = defaultDateFormat;
        // if the field has a format specified and is not default use it
        if (f.dateFormat != null && f.dateFormat !== 'default') {
          dateFormat = f.dateFormat;
        }
        return {
          ...f,
          value: f.value || moment().format(dateFormat),
        };
      }
      return f;
    },
    [defaultDateFormat, inlinePreviewMode],
  );
  const [{ fields, hiddenFieldsWithValues, rules }, setState] = React.useState(
    () => {
      const fields = signatureRequest.fields.map(normalizeField);
      return {
        fields,
        hiddenFieldsWithValues: 0,
        rules: signatureRequest.rules,
      };
    },
  );

  const onFieldUpdateQueue = React.useRef<Parameters<typeof onFieldUpdate>[]>(
    [],
  );

  /**
   * This component was originally designed to receive fields, and then it
   * maintains its own copy by applying updates locally. For DocumentPreview,
   * this needs a way to pass a different set of fields without having to just
   * use a `key={}` that resets everything.
   */
  React.useEffect(() => {
    if (fieldResetKey) {
      onFieldUpdateQueue.current.length = 0; // reset the queue before resetting the fields
      const fields = signatureRequest.fields.map(normalizeField);
      setState({
        fields,
        hiddenFieldsWithValues: 0,
        rules: signatureRequest.rules,
      });
    }

    // This needs to reset when fieldResetKey changes but NOT when
    // signatureRequest changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [normalizeField, fieldResetKey]);
  useSkipHiddenFields(fields, selectedFieldIds, setCurrentField);
  useLinkNotification(selectedFieldIds[0], fields);

  React.useEffect(() => {
    if (hiddenFieldsWithValues > 0) {
      sendNotification({
        autoClose: true,
        withCloseButton: true,
        delay: 4000,
        message: intl.formatMessage(messages.hiddenFieldsNotification, {
          hiddenFields: hiddenFieldsWithValues,
        }),
        type: 'attention',
      });

      setState((state) => ({ ...state, hiddenFieldsWithValues: 0 }));
    }
  }, [sendNotification, hiddenFieldsWithValues, intl, signatureRequest.rules]);

  const updateFields = React.useCallback(
    (updates) => {
      setState((state) => {
        let hiddenFieldsWithValues = 0;

        const checkHidden = (field: Field, updates: Partial<Field>) => {
          if (updates.hidden != null) {
            if (updates.hidden) {
              switch (field.type) {
                case 'radiobutton':
                case 'checkbox':
                  if (field.checked) {
                    hiddenFieldsWithValues++;
                  }
                  break;
                case 'dropdown':
                case 'hyperlink':
                case 'text':
                  if (field.value) {
                    hiddenFieldsWithValues++;
                  }

                  break;
                case 'initials':
                case 'signature':
                  if (field.signature) {
                    hiddenFieldsWithValues++;
                  }
                  break;
                case 'image':
                case 'rectangle':
                case 'date':
                  // Dates don't need to notify the user that a value was removed,
                  // because it's not a value the user provides.
                  break;
                default:
                  unreachable(field);
              }
            }
          }

          onFieldUpdateQueue.current.push([field, updates]);
        };

        let radioUpdates:
          | undefined
          | Record<RadioField['group'], RadioField['id']>;
        let linkIdsToValues:
          | undefined
          | Record<NonNullable<Field['linkId']>, Partial<Field>>;

        // collect link ids for each field update
        function collectLinkedFieldUpdates(
          field: Partial<Field>,
          update: FieldUpdate,
        ): void {
          if ('value' in update && field.linkId) {
            linkIdsToValues = {
              ...linkIdsToValues,
              [field.linkId]: update,
            };
          }
        }

        function mergeLinkedFieldUpdates(
          fields: Field[],
          linkIdsToValues:
            | undefined
            | Record<NonNullable<Field['linkId']>, Partial<Field>>,
        ) {
          if (linkIdsToValues === null) {
            return fields;
          }
          return fields.map((f) => {
            if (f.linkId && linkIdsToValues && linkIdsToValues[f.linkId]) {
              checkHidden(f, linkIdsToValues[f.linkId]);
              return { ...f, ...linkIdsToValues[f.linkId] };
            }
            return f;
          });
        }

        let fields = state.fields.map((f) => {
          if (updates[f.id] != null) {
            if (f.type === 'radiobutton' && updates[f.id].checked) {
              radioUpdates = {
                ...radioUpdates,
                [f.group]: f.id,
              };
            }
            collectLinkedFieldUpdates(f, updates[f.id]);
            checkHidden(f, updates[f.id]);

            return { ...f, ...updates[f.id] };
          }
          return f;
        });
        fields = mergeLinkedFieldUpdates(fields, linkIdsToValues);
        if (radioUpdates) {
          const update = { checked: false };
          fields = fields.map((f) => {
            // uncheck all the other items in the group
            if (
              radioUpdates &&
              radioUpdates[f.group] &&
              f.id !== radioUpdates[f.group]
            ) {
              checkHidden(f, update);
              return { ...f, ...update };
            }
            return f;
          });
        }

        return {
          fields,
          hiddenFieldsWithValues,
          rules: signatureRequest.rules,
        };
      });
    },
    [signatureRequest.rules],
  );

  React.useEffect(
    () => {
      // A previous version attempted to store this queue in state, next to
      // fields. That caused some weird race conditions where some updates got
      // lost. Text fields fire one change for the value and a separate change for
      // lines and font size. Those two updates happen in the same tick, so the
      // queue was updated twice before the next render could happen and run the
      // queue.
      const queue = onFieldUpdateQueue.current;
      while (queue.length > 0) {
        // This needs to use a queue becuase onFieldUpdate needed to be moved
        // outside the `setState()` callback inside `updateFields()`. React
        // didn't like it that `onFieldUpdate` changes DocumentPreview's state
        // while in the middle of a state update here in SignerSignatureDocument.
        //
        // Warning: Cannot update a component from inside the function body of a different component.
        const [field, updates] = queue.shift()!;
        onFieldUpdate(field, updates);
      }
    } /* Run on EVERY render */,
  );

  const [activeSignatures, setActiveSignatures] = React.useState<
    SignerContextShape['activeSignatures']
  >({
    signature: null,
    initials: null,
  });

  const visibleFields = React.useMemo(
    () => fields.filter((f) => f.hidden !== true),
    [fields],
  );

  const { emptySignatureIds, emptyInitialIds } = React.useMemo(
    () =>
      visibleFields.reduce(
        (counts, fieldData) => {
          if (fieldData.type === 'signature' || fieldData.type === 'initials') {
            if (!fieldData.readOnly && fieldData.signature == null) {
              if (fieldData.type === 'signature') {
                counts.emptySignatureIds.push(fieldData.id);
              } else {
                counts.emptyInitialIds.push(fieldData.id);
              }
            }
          }
          return counts;
        },
        {
          emptySignatureIds: [] as Array<Field['id']>,
          emptyInitialIds: [] as Array<Field['id']>,
        },
      ),
    [visibleFields],
  );

  const insertSignature = React.useCallback(
    (signature, fieldData, insertEverywhere) => {
      let fieldsToUpdate = [fieldData.id];
      if (insertEverywhere) {
        fieldsToUpdate =
          fieldData.type === 'signature' ? emptySignatureIds : emptyInitialIds;
      }

      const updates = fieldsToUpdate.reduce((updates, id) => {
        updates[id] = { signature };
        return updates;
      }, {});

      // if "clearing" the field, let's not null the property since signature is passed as null
      if (signature != null) {
        const fieldType = fieldData.type;
        if (!documentPreview) {
          setActiveSignatures((state) => ({
            ...state,
            [fieldType]: signature,
          }));
        }
      }

      updateFields(updates);
    },
    [documentPreview, emptyInitialIds, emptySignatureIds, updateFields],
  );

  useConditionalLogic(
    fields,
    rules,
    // this hook will call `updateFields` any time something needs to change.
    updateFields,
  );

  /**
   * Putting the fields through useLatestRef allows me to read the current
   * fields without having to make them a dependency of `findNearestField`,
   * which is a dependency of handlePageClick
   */
  const latestFieldsRef = useLatestRef(visibleFields);
  const findFieldWithinDistance = React.useCallback(
    (address: DocumentAddress, maxDistance: number): Field | null => {
      const distance = (field: Field) => {
        const fieldCenter = {
          x: field.x + field.width / 2,
          y: field.y + field.height / 2,
        };
        return Math.sqrt(
          (fieldCenter.x - address.x) ** 2 + (fieldCenter.y - address.y) ** 2,
        );
      };

      const [field] = latestFieldsRef.current.reduce(
        ([maybeField, lastDistance], field) => {
          if (field.pageIndex === address.pageIndex) {
            const distanceToClick = distance(field);
            if (distanceToClick <= maxDistance) {
              if (distanceToClick < lastDistance || maybeField == null) {
                return [field, distanceToClick];
              }
            }
          }
          return [maybeField, lastDistance];
        },
        [null, 0] as [Field | null, number],
      );
      return field;
    },
    [latestFieldsRef],
  );

  const handlePageClick = React.useCallback(
    (
      _event: React.MouseEvent,
      _pageIndex: number,
      address: DocumentAddress,
    ) => {
      if (document.activeElement != null) {
        // activeElement can be any kind of Element, which means it might not have
        // a .blur(). To make this code explicit, I'm casting it to what it'll be
        // most of the time, but also using the `?.()` to prevent crashing when it
        // does end up with a plain Element.
        (document.activeElement as HTMLElement).blur?.();
      }

      // The address was constructed to be 48x48px converted to document
      // coordinates.
      const mobileTargetRadius = address.width / 2;
      const field = findFieldWithinDistance(address, mobileTargetRadius);

      setCurrentField(field?.id ?? null);
      if (field) {
        switch (field.type) {
          // @ts-ignore fallthrough
          case 'radiobutton':
            // Clicking a radio button that's selected doesn't de-select it, so
            // clicking near it shouldn't either.
            if (field.checked) {
              break;
            }
          // fallthrough
          case 'checkbox':
            if (!field.readOnly) {
              updateFields({
                [field.id]: { checked: !field.checked },
              });
            }
            break;
          case 'hyperlink':
          case 'signature':
          case 'initials': {
            const el = document.getElementById(`${field.type}-${field.id}`);
            el?.click();
            break;
          }
          case 'date':
          case 'text':
          case 'dropdown':
          case 'image':
          case 'rectangle':
            // None of these fields need special handling
            break;
          default:
            throw new UnreachableError(field);
        }
      }

      if (onPageClick) {
        onPageClick();
      }
    },
    [findFieldWithinDistance, onPageClick, setCurrentField, updateFields],
  );

  const value: SignerContextShape = React.useMemo(
    () => ({
      isPreview: false,
      emptySignatureCount: emptySignatureIds.length,
      emptyInitialsCount: emptyInitialIds.length,
      startEnabled,
      validationErrors,
      updateFields: startEnabled ? updateFields : NOOP,
      selectField: startEnabled ? setCurrentField : NOOP,
      insertSignature,
      onPageClick: handlePageClick,
      getInstantValidationErrorHack,
      activeSignatures,
      documentTitle,
    }),
    [
      activeSignatures,
      documentTitle,
      emptyInitialIds.length,
      emptySignatureIds.length,
      getInstantValidationErrorHack,
      handlePageClick,
      insertSignature,
      setCurrentField,
      startEnabled,
      updateFields,
      validationErrors,
    ],
  );

  const fieldsToPrefill = React.useMemo(() => {
    const { signedData } = signatureRequest;
    if (!signedData) return [];
    return fields.filter((field) => {
      const signedField = signedData[field.name];
      return signedField && signedField.type === serializeFieldType(field);
    });
  }, [signatureRequest, fields]);

  const handleRepopulate = React.useCallback(() => {
    const { signedData } = signatureRequest;
    const updates: Record<string, Record<string, any>> = {};
    if (onPrefill && signedData) {
      fieldsToPrefill.forEach((field) => {
        const signedField = signedData[field.name];
        if (['inputsignature', 'inputinitials'].includes(signedField.type)) {
          insertSignature(signedField.data, field, false);
        } else {
          updates[field.id] = signedField.data;
        }
      });

      if (Object.keys(updates).length > 0) {
        updateFields(updates);
        onPrefill();
      }
    }
  }, [
    fieldsToPrefill,
    insertSignature,
    updateFields,
    signatureRequest,
    onPrefill,
  ]);

  return (
    <signerContext.Provider value={value}>
      <SignatureRequestContext
        selectedFieldIds={selectedFieldIds}
        dpi={signatureRequest.dpi}
        pages={signatureRequest.pages}
        documentPreview={documentPreview}
        fields={visibleFields}
        inlinePreviewMode={inlinePreviewMode}
        lastUpdatedFieldId={lastUpdatedFieldId}
        containerWidth={containerWidth}
      >
        {(documentPreview && <ValidationNotification />) ||
          (onPrefill && fieldsToPrefill.length > 0 && (
            <SignedDataModal onConfirm={handleRepopulate} />
          ))}
        {children}
      </SignatureRequestContext>
    </signerContext.Provider>
  );
}
