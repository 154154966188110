import { defineMessages } from 'react-intl';
import { unreachable } from 'signer-app/utils/unreachable';
import { AutofillFieldTypes } from 'signer-app/types/editor-types';

export const autofillFieldMessages = defineMessages({
  companyFieldLabel: {
    id: 'b015515599e3b5f6de6fe0235572ae143033f1afc1bafa021852160ff5f65df8',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Company" field.',
    defaultMessage: 'Company',
  },
  emailFieldLabel: {
    id: 'ad1851d1ff574c0b525dab1fe3c2f7793bd35be4c4c9ed422ddbdba9b46880f2',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Email" field.',
    defaultMessage: 'Email',
  },
  emailAddressFieldLabel: {
    id: '0a8c6e48779ee51a80564368116d9bb4e77a5f02463787377ab0f6fe31efe653',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Email" field.',
    defaultMessage: 'Email address',
  },
  fullNameFieldLabel: {
    id: 'c3ca77072970c578c0b2a28149074af2072ee3f0588c72aa3d7a756847b641c5',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Full name" field.',
    defaultMessage: 'Full name',
  },
  lastNameFieldLabel: {
    id: '855432e6e8000fd1972e7a67ecbf2bdc122554f21c08ec7a01682ab3a8853ec0',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Last name" field.',
    defaultMessage: 'Last name',
  },
  firstNameFieldLabel: {
    id: 'b073bb1eaff20a7faf888012798522621b1e49d4c5131a55a68da3c076482de5',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "First name" field.',
    defaultMessage: 'First name',
  },
  titleFieldLabel: {
    id: '246350a88f63722a072c7e42945014694a801e9400e47c76f0c86364a5b42397',
    description:
      'Text describing a type of field a user can drag into the document. In this case, a "Job Title" field. This field describes a role or designation.',
    defaultMessage: 'Title',
  },
});

export function getAutofillFieldMessage(
  // The template literal allows us to use names that still get type-checked
  // without having to import the enum.
  type: AutofillFieldTypes | `${AutofillFieldTypes}`,
) {
  // This does type narrowing without having to create a string case (`case
  // 'name':`) for each item.
  const t = type as AutofillFieldTypes;

  switch (t) {
    /* eslint-disable max-len */
    case AutofillFieldTypes.FullName:
      return autofillFieldMessages.fullNameFieldLabel;
    case AutofillFieldTypes.FirstName:
      return autofillFieldMessages.firstNameFieldLabel;
    case AutofillFieldTypes.LastName:
      return autofillFieldMessages.lastNameFieldLabel;
    case AutofillFieldTypes.Company:
      return autofillFieldMessages.companyFieldLabel;
    case AutofillFieldTypes.Email:
      return autofillFieldMessages.emailAddressFieldLabel;
    case AutofillFieldTypes.Title:
      return autofillFieldMessages.titleFieldLabel;
    case AutofillFieldTypes.EmailOrPhoneNumber: // shouldn't be exposed on the frontend anyways
    case AutofillFieldTypes.None:
      return '';
    /* eslint-enable max-len */
    default:
      unreachable(t);
      return '';
  }
}
