import { Field } from 'signer-app/types/editor-types';
import { unreachable } from 'signer-app/utils/unreachable';
import { Rule, Trigger, Updates } from 'signer-app/conditional-logic/types';
import { isSorted } from 'signer-app/conditional-logic/sort-rules';

export function evaluateRule(fields: Field[], rule: Rule): boolean {
  function evaluateTrigger(trigger: Trigger) {
    const field = fields.find((f) => f.id === trigger.id);
    if (!field) {
      throw new Error(`Unable to find field: ${trigger.id}`);
    }
    if (field.hidden) {
      return false;
    }

    switch (field.type) {
      case 'radiobutton': {
        if (trigger.operator === 'is' || trigger.operator === 'not') {
          const checked = trigger.operator === 'is';
          return fields.some(
            (f) =>
              f.type === 'radiobutton' &&
              f.group === field.group &&
              trigger.value === f.id &&
              Boolean(f.checked) === checked,
          );
        } else if (trigger.operator === 'any' || trigger.operator === 'none') {
          const isChecked = (f: Field) =>
            f.type === 'radiobutton' &&
            f.group === field.group &&
            trigger.value.includes(f.id) &&
            Boolean(f.checked) === true;
          if (trigger.operator === 'any') {
            return fields.some(isChecked);
          } else if (trigger.operator === 'none') {
            return !fields.some(isChecked);
          }
        }

        throw new Error(
          `Unsupported operator ${field.type} / ${trigger.operator}`,
        );
      }
      case 'checkbox': {
        if (trigger.operator === 'is') {
          if (typeof trigger.value !== 'boolean') {
            throw new Error('Invalid rule: checkbox values must be boolean');
          }

          return Boolean(field.checked) === trigger.value;
        } else if (trigger.operator === 'not') {
          if (typeof trigger.value !== 'boolean') {
            throw new Error('Invalid rule: checkbox values must be boolean');
          }

          return Boolean(field.checked) !== trigger.value;
        }
        throw new Error(
          `Unsupported operator ${field.type} / ${trigger.operator}`,
        );
      }
      case 'text':
      case 'dropdown': {
        if (trigger.operator === 'is') {
          if (trigger.value === '' || trigger.value === undefined) {
            return (
              field.value === '' ||
              field.value === undefined ||
              field.value === null
            );
          }
          return field.value === trigger.value;
        } else if (trigger.operator === 'not') {
          if (trigger.value === '' || trigger.value === undefined) {
            return (
              field.value !== '' &&
              field.value !== undefined &&
              field.value !== null
            );
          }
          return field.value !== trigger.value;
        } else if (trigger.operator === 'any') {
          return field.value != null && trigger.value.indexOf(field.value) >= 0;
        } else if (trigger.operator === 'none') {
          return (
            field.value == null || trigger.value.indexOf(field.value) === -1
          );
        } else if (trigger.operator === 'has') {
          return (
            field.value != null && field.value.indexOf(trigger.value) !== -1
          );
        } else if (trigger.operator === 'hasNot') {
          return (
            field.value == null || field.value.indexOf(trigger.value) === -1
          );
        } else if (trigger.operator === 'match') {
          const re = new RegExp(trigger.value);

          return (
            typeof field.value === 'string' &&
            field.value.length > 0 &&
            re.exec(field.value) != null
          );
        } else if (trigger.operator === 'gt' || trigger.operator === 'lt') {
          if (field.value == null) {
            return false;
          }

          const num = parseFloat(field.value);
          if (!Number.isNaN(num)) {
            return trigger.operator === 'gt'
              ? num > trigger.value
              : num < trigger.value;
          }

          return false;
        }

        break;
      }
      case 'signature':
      case 'initials':
      case 'date':
      case 'hyperlink':
      case 'rectangle':
      case 'image':
        throw new Error(`Unsupported field type: ${field.type}`);
      default:
        unreachable(field);
    }

    return false;
  }

  if (rule.triggerOperator === 'AND') {
    return rule.triggers.reduce(
      (acc: boolean, trigger) => acc && evaluateTrigger(trigger),
      true,
    );
  }
  return rule.triggers.reduce(
    (acc: boolean, trigger) => acc || evaluateTrigger(trigger),
    false,
  );
}

export const applyUpdates = (fields: Field[], updates: Updates) =>
  fields.map((f) => {
    if (updates[f.id] != null) {
      return Object.assign({}, f, updates[f.id]);
    }
    return f;
  });

export function buildUpdates(fields: Field[], rules: Rule[]): Updates {
  if (!isSorted(rules)) {
    // sorting also validates that there are no loops. The result should be
    // cached instead of sorting before every run.
    throw new Error('Rules must be sorted before running');
  }

  const { updates } = rules.reduce(
    (acc, rule) => {
      const { updates } = acc;
      let { fields } = acc;

      // We need to be able to apply updates for matching rules AND reverse those
      // when the rule doesn't match.
      const match = evaluateRule(fields, rule);

      // Walk all the fields once and update any that need changes.
      fields = fields.map((f) => {
        let field = f;
        let index = -1;

        do {
          // eslint-disable-next-line no-loop-func
          index = rule.actions.findIndex((a, i) => {
            // always start the search at the NEXT index
            if (i <= index) {
              return false;
            } else if (a.type === 'change-field-visibility') {
              return f.id === a.fieldId;
            } else if (a.type === 'change-group-visibility') {
              return 'group' in f && f.group === a.groupId;
            }

            unreachable(a);
            return false;
          });

          // If a target is found
          if (index >= 0) {
            const action = rule.actions[index];

            if (
              action.type === 'change-field-visibility' ||
              action.type === 'change-group-visibility'
            ) {
              const value = match ? action.hidden : !action.hidden;
              // action.required is optional, so default to whatever the current
              // value is
              let required = field.required;
              const originalRequired = Boolean(
                field.originalRequired || field.required,
              );
              if (value === true) {
                // We can't require a hidden field because it would prevent the
                // user from completing the document.
                required = false;
              } else {
                required = originalRequired;
              }

              if (
                Boolean(field.hidden) !== value ||
                field.required !== required ||
                Boolean(field.originalRequired) !== originalRequired
              ) {
                // normally I wouldn't mutate a parameter (acc.updates) like this, but updates
                // was created in the 2nd parameter of `rules.reduce`
                updates[f.id] = updates[f.id] || {};
                updates[f.id].hidden = value;
                updates[f.id].required = required;
                updates[f.id].originalRequired = originalRequired;

                // replace field with a modified version. It's important to update
                // the field immediately to account for an edge case where one
                // rule has multiple actions that might change the same field. It
                // may not be something we want to intentionally do, but the data
                // structure doesn't prevent it.
                field = Object.assign({}, field, updates[f.id]);
              }
            } else {
              unreachable(action);
              throw new Error(`Unhandled target: ${JSON.stringify(action)}`);
            }
          }
        } while (index >= 0);

        // if the field never matched a target, this just returns the original
        // object.
        return field;
      });

      return { fields, updates };
    },
    { fields, updates: {} } as { fields: Field[]; updates: Updates },
  );

  return updates;
}

export default function runRules(fields: Field[], rules: Rule[]): Field[] {
  const updates = buildUpdates(fields, rules);
  return applyUpdates(fields, updates);
}
