import { Checkbox, RadioButton } from '@dropbox/dig-components/controls';
import {
  FormControlGroup,
  FormHelperText,
  FormLabel,
  FormRow,
} from '@dropbox/dig-components/form_row';
import { Select, TextInput } from '@dropbox/dig-components/text_fields';
import { UIIcon } from '@dropbox/dig-icons';
import { FailLine } from '@dropbox/dig-icons/assets';
import React from 'react';
import {
  signatureRequestContext,
  useFieldsContext,
} from 'signer-app/signature-request/context';
import { FieldContextProvider } from 'signer-app/signature-request/display-field';
import { useSignerContext } from 'signer-app/signer-experience/signer-experience';
import SignaturePlaceholder from 'signer-app/signer-signature-document/signature-placeholder';
import { Field } from 'signer-app/types/editor-types';
import { UnreachableError } from 'signer-app/utils/unreachable';

function FieldLabel({ field }: { field: Field }) {
  return (
    <FormLabel htmlFor={`field_${field.id}`}>
      {field.label ??
        field.formLabel ??
        field.name ??
        field.autoFillType ??
        field.id}
    </FormLabel>
  );
}

type FieldProps = {
  field: Field;
};
function FieldInput({ field }: FieldProps) {
  const { updateFields, selectField } = useSignerContext();
  const { selectedFieldIds } = React.useContext(signatureRequestContext);
  const type = field.type;
  const isActive = selectedFieldIds.includes(field.id);
  const handleFocus = React.useCallback(() => {
    if (!isActive) {
      selectField(field.id);
    }
  }, [field.id, isActive, selectField]);

  switch (type) {
    case 'text':
    case 'date':
      return (
        <TextInput
          placeholder={field.placeholder}
          value={field.value ?? ''}
          onFocus={handleFocus}
          onChange={(e) =>
            updateFields({
              [field.id]: {
                value: e.target.value,
                lines: e.target.value,
              },
            })
          }
          id={`field_${field.id}`}
          readOnly={field.type === 'date' || field.readOnly}
          aria-describedby={`validation_${field.id}`}
        />
      );
    case 'dropdown':
      return (
        <Select
          id={`field_${field.id}`}
          onFocus={handleFocus}
          onChange={(value) =>
            updateFields({
              [field.id]: { value },
            })
          }
          aria-describedby={`validation_${field.id}`}
        >
          {field.options.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      );
    case 'checkbox':
      return (
        <Checkbox
          onChange={(e) =>
            updateFields({
              [field.id]: {
                checked: e.target.checked,
              },
            })
          }
          onFocus={handleFocus}
          id={`field_${field.id}`}
          checked={field.checked ?? false}
          aria-describedby={`validation_${field.id}`}
        />
      );
    case 'radiobutton':
      return (
        <RadioButton
          onChange={(e) =>
            updateFields({
              [field.id]: {
                checked: e.target.checked,
              },
            })
          }
          onFocus={handleFocus}
          name={`radio_${field.id}`}
          checked={field.checked}
          id={`field_${field.id}`}
        />
      );
    case 'signature':
    case 'initials':
      return (
        <FieldContextProvider
          fieldData={field}
          wrapperStyles={{
            height: '100',
            width: '400',
          }}
        >
          <div style={{ height: '3em', width: '100%', position: 'relative' }}>
            <SignaturePlaceholder fieldData={field} textScale={1} />
          </div>
        </FieldContextProvider>
      );
    case 'rectangle':
    case 'hyperlink':
    case 'image':
      return <div>{field.type}</div>;
    default:
      throw new UnreachableError(type);
  }
}

type GroupProps = {
  field: Field;
  group: Field[];
};
function FieldGroup({ group }: GroupProps) {
  // return group.map((field) => (
  //   <SingleField key={field.id} field={field} />
  // ));
  return (
    <FormControlGroup>
      {group.map((field) => (
        <FormRow key={field.id} variant="control">
          <FieldInput field={field} />
          <FieldLabel field={field} />
        </FormRow>
      ))}
    </FormControlGroup>
  );
}

export function AccessibleDocument() {
  const fields = useFieldsContext();
  const { validationErrors } = useSignerContext();

  const groupedFields = React.useMemo(() => {
    return fields.reduce(
      (groups, f) => {
        if ('group' in f && f.group != null) {
          groups[f.group] = groups[f.group] || [];
          groups[f.group].push(f);
        }
        return groups;
      },
      {} as Record<string, Field[]>,
    );
  }, [fields]);

  const skipGroupedField = (field: Field) => {
    if ('group' in field && field.group != null) {
      return groupedFields[field.group][0] !== field;
    }
    return false;
  };

  return (
    <div style={{ maxWidth: '480px', margin: 'auto' }}>
      {fields
        .filter((f) => !f.hidden && !skipGroupedField(f))
        .map((field) => (
          <FormRow key={field.id}>
            {
              // type-narrow to types that can be grouped
              'group' in field &&
              // Make sure we have a group a group
              field.group != null ? (
                <FieldGroup field={field} group={groupedFields[field.group!]} />
              ) : (
                <>
                  <FieldLabel field={field} />
                  <FieldInput field={field} />
                </>
              )
            }
            {validationErrors[field.id] && (
              <FormHelperText id={`validation_${field.id}`} variant="alert">
                <UIIcon
                  src={FailLine}
                  size="small"
                  aria-label="Validation Error Icon"
                />
                <span> {validationErrors[field.id].message}</span>
              </FormHelperText>
            )}
          </FormRow>
        ))}
    </div>
  );
}
